










import { Component, Vue } from "vue-property-decorator"
import firebase from "firebase/app"

@Component
export default class extends Vue {
  async signIn() {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
    googleAuthProvider.setCustomParameters({ prompt: 'select_account', hd: 'htempest.co.uk' })
    await firebase.auth().signInWithPopup(googleAuthProvider)
    this.$router.push('/')
  }
}
